body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
  margin: unset;
  padding: unset;
  font-family: 'Inter', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* DEV MODES */

.login-splash-main {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;
}

#login-form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

input.login-input {
  height: 30px;
  margin-top: 10px;
  min-width: 60px;
  outline: none;
  border-radius: 5px;
  font-size: larger;
  padding-left: 3px;
  padding-right: 3px;
}

button.login-input {
  margin-top: 15px;
  width: calc(30% + 10px);
  min-width: 60px;
  max-width: 150px;
  height: 35px;
  background-color: rgb(74, 74, 251);
  color: white;
  font-size: large;
  border: none;
  border-radius: 3px;
  outline: none;
}

button.login-input:hover {
  background-color: blue;
  cursor: pointer;
}

.error {
  padding: 20px;
}

.error-btn {
  cursor: pointer;
  padding: 8px;
  border: 1px solid white;
  color: white;
  background-color: transparent;
  text-decoration: none;
}

.error-btn:hover {
  background-color: white;
  color: black;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

video {
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
}