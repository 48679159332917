@import url(https://fonts.googleapis.com/css2?family=Inter:wght@900&display=swap);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
  margin: unset;
  padding: unset;
  font-family: 'Inter', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* DEV MODES */

.login-splash-main {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;
}

#login-form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

input.login-input {
  height: 30px;
  margin-top: 10px;
  min-width: 60px;
  outline: none;
  border-radius: 5px;
  font-size: larger;
  padding-left: 3px;
  padding-right: 3px;
}

button.login-input {
  margin-top: 15px;
  width: calc(30% + 10px);
  min-width: 60px;
  max-width: 150px;
  height: 35px;
  background-color: rgb(74, 74, 251);
  color: white;
  font-size: large;
  border: none;
  border-radius: 3px;
  outline: none;
}

button.login-input:hover {
  background-color: blue;
  cursor: pointer;
}

.error {
  padding: 20px;
}

.error-btn {
  cursor: pointer;
  padding: 8px;
  border: 1px solid white;
  color: white;
  background-color: transparent;
  text-decoration: none;
}

.error-btn:hover {
  background-color: white;
  color: black;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

video {
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
}
.pr-loading-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Inter', sans-serif;
  font-weight: bolder;
}

.pr-loading-logo {
  width: 100px;
  height: 100px;
  margin-bottom: 15px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.logo-1 {
  background-image: url(/static/media/shield_01.43bc5517.png);
}

.logo-2 {
  background-image: url(/static/media/sheild_02.6bc170e1.png);
}

.logo-3 {
  background-image: url(/static/media/sheild_03.416a7676.png);
}

.logo-4 {
  background-image: url(/static/media/sheild_04.2d0f793e.png);
}

.logo-5 {
  background-image: url(/static/media/sheild_05.f183f424.png);
}

.logo-6 {
  background-image: url(/static/media/sheild_06.3045b8e0.png);
}

.logo-7 {
  background-image: url(/static/media/sheild_07.d5bbd37e.png);
}

.logo-8 {
  background-image: url(/static/media/sheild_08.a2bce375.png);
}

.logo-9 {
  background-image: url(/static/media/sheild_09.631ee087.png);
}

.logo-10 {
  background-image: url(/static/media/sheild_10.859db7d5.png);
}

.logo-11 {
  background-image: url(/static/media/sheild_11.6875a594.png);
}

.logo-12 {
  background-image: url(/static/media/sheild_12.bf9dd5ee.png);
}

.logo-main {
  background-image: url(/static/media/logo-main.42990aa4.png);
}

@-webkit-keyframes fadeUp {
  from {
    -webkit-transform: translate3d(0, 55px, 0);
            transform: translate3d(0, 55px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fadeUp {
  from {
    -webkit-transform: translate3d(0, 55px, 0);
            transform: translate3d(0, 55px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.logo-header {
  width: 100%;
  height: 185px;
  margin-top: 30px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
}

.main-bkgd {
  min-height: 100vh;
  max-height: 100vh;
  min-width: 100vw;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-bkgd video {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: 0;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
}

.home-content {
  position: absolute;
  top: 0;
  min-height: 100%;
  max-height: 100%;
  min-width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
}

.code-input-container {
  display: none;
}

.code-input-container.open {
  display: block;
  -webkit-animation-name: fadeUp;
          animation-name: fadeUp;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

h1.invitation {
  font-family: 'Inter', sans-serif;
  padding-left: 20px;
  padding-right: 20px;
}

.invite-code-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.invite-code-form input {
  height: 45px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: rgba(0, 0, 0, 0.322);
  border: 2px solid white;
  color: white;
  font-family: 'Inter', sans-serif;
  text-transform: capitalize;
  outline: none;
  font-weight: bold;
  font-size: larger;
  padding-left: 8px;
}

.invite-code-form button {
  height: 51px;
  background-color: white;
  border-top: 2px solid white;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  border-left: none;
  outline: none;
  color: black;
  font-size: larger;
  font-family: 'Inter', sans-serif;
  font-weight: bolder;
}

.invite-code-form button:hover {
  background-color: rgba(0, 0, 0, 0.322);
  color: white;
  cursor: pointer;
}

.invite-code-error {
  /* display: none; */
  height: 20px;
  margin-top: 18px;
}

.invite-code-error.open {
  display: block;
  color: crimson;
  font-family: 'Inter', sans-serif;
  font-size: large;
  padding-left: 20px;
  padding-right: 20px;
}

.spacer {
  flex-grow: 1;
}

.loader {
  border: 5px solid black;
  border-top: 5px solid white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
  margin-left: 10px;
  margin-right: 10px;
}

.loader:hover {
  border: 5px solid white;
  border-top: 5px solid black;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media screen and (max-width: 600px) {
  .intro-text {
    font-size: 30px;
  }
  .code-input-container {
    max-width: 95%;
  }
  .invite-code-form {
    max-width: 95%;
  }
  .invite-code-form input {
    width: 100%;
  }
}

section {
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  overflow: hidden;
}

section .sec1 {
  /* background: url("../images/home.jpg"); */
  background-size: cover;
  background-position: center center;
  height: 500px;
}

section .sec2 {
  background: #fff;
  height: 450px;
}

section .sec3 {
  background: lightgray;
  background-size: cover;
  background-position: center center;
  height: 800px;
}

section .sec4 {
  background: #fff;
  height: 800px;
}

.skewed1 {
  position: absolute;
  bottom: 0;
  left: 0;
  border-left-width: 100vw;
  border-top-width: 33px;
  border-right-width: 0;
  border-bottom-width: 0;
  border-color: transparent transparent transparent #252525;
  border-style: solid;
  z-index: 100;
}

.skewed2 {
  position: absolute;
  top: 0;
  left: 0;
  border-left-width: 100vw;
  border-top-width: 0;
  border-right-width: 0;
  border-bottom-width: 33px;
  border-color: transparent transparent transparent #252525;
  border-style: solid;
  z-index: 100;
}

.skewed2-u {
  position: absolute;
  top: 0;
  left: 0;
  border-left-width: 100vw;
  border-top-width: 33px;
  border-right-width: 0;
  border-bottom-width: 0;
  border-color: #252525 transparent transparent transparent;
  border-style: solid;
  z-index: 100;
}

.skewed3 {
  position: absolute;
  bottom: 0;
  left: 0;
  border-left-width: 100vw;
  border-top-width: 33px;
  border-right-width: 0;
  border-bottom-width: 0;
  border-color: transparent transparent transparent #252525;
  border-style: solid;
  z-index: 100;
}

.skewed3-u {
  position: absolute;
  bottom: 0;
  left: 0;
  border-left-width: 0;
  border-top-width: 33px;
  border-right-width: 100vw;
  border-bottom-width: 0;
  border-color: transparent #252525 #252525 #252525;
  border-style: solid;
  z-index: 100;
}

.final-sec {
  height: 200px;
  padding: 20px;
  max-width: 100%;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pdf-view {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.pdf-button {
  cursor: pointer;
  padding: 10px;
  background-color: transparent;
  border: 1px solid green;
  color: white;
  font-family: "Inter", sans-serif;
  font-size: large;
  font-weight: bold;
}

.pdf-button:hover {
  border-color: crimson;
  background-color: white;
  color: black;
}

.pdf-button span {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  text-align: center;
}

.inverse {
  background-color: black;
}

.inverse:hover {
  background-color: transparent;
  color: black;
}

.header-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.header-video-container {
  max-height: 750px;
  min-height: 750px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-headlies-container {
  min-width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.header-headline-row {
  min-width: calc(100% - 40px);
  max-width: calc(100% - 40px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-left: 20px;
  padding-right: 20px;
}

.header-headline {
  flex: 1 1;
  padding: 15px;
  text-align: center;
  margin-top: 200px;
  margin-bottom: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.header-headline h1 {
  font-size: 40px;
  margin-top: 8px;
  margin-bottom: unset;
}

.header-headline p.text {
  text-align: center;
  font-size: 20px;
}

.header-content p {
  padding: 25px;
  text-align: left;
}

.header-content p a {
  text-decoration: none;
  color: green;
}

.header-content p a:hover {
  text-decoration: overline;
  color: rgb(0, 200, 0);
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;
}

.col {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
  margin-left: 15px;
  flex-direction: column;
  flex: 1 1;
  margin-bottom: 50px;
}

.col2 {
  flex: 1 1;
  background-color: #252525;
}

.col p {
  font-size: large;
}

.img-1 {
  background: url(/static/media/architecture.7f6f8529.jpg);
  background-size: cover;
  background-position: center center;
  height: 800px;
  max-height: 800px;
  min-height: 800px;
  width: 100%;
  margin: unset;
}

.img-2 {
  background: url(/static/media/porsche1.e8e40fb0.jpg);
  background-size: cover;
  background-position: center center;
  height: 800px;
  max-height: 800px;
  min-height: 800px;
  width: 100%;
  margin: unset;
}

.img-3 {
  background: url(/static/media/backyard.4a40be66.jpeg);
  background-size: cover;
  background-position: center center;
  height: 800px;
  max-height: 800px;
  min-height: 300px;
  width: 100%;
  margin: unset;
}

.col h1 {
  border-bottom: 1px solid crimson;
  font-size: 30px;
}

.col2 video {
  width: 100%;
  min-height: 800px;
  object-fit: cover;
  z-index: -1;
  opacity: 0.85;
}

.future-motion {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 200px;
  margin-bottom: 200px;
  -webkit-transform: skew(-4deg, -4deg);
          transform: skew(-4deg, -4deg);
}

.future-motion h1.future {
  font-size: 120px;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-weight: bolder;
  color: black;
  margin: unset;
}

.tri-bar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 60%;
  max-width: 60%;
  margin-bottom: 50px;
}

.tri-bar-container .red {
  flex: 1 1;
  background-color: crimson;
  height: 2px;
}

.tri-bar-container .green {
  flex: 1 1;
  background-color: green;
  height: 2px;
}

.tri-bar-container .white {
  flex: 1 1;
  background-color: white;
  height: 2px;
}

.project-overview {
  background-color: #fff;
  color: #252525;
}

@media screen and (max-width: 1070px) {
  .row {
    flex-direction: column;
  }
  .col {
    margin-left: unset;
  }
  .future-motion {
    margin-top: 100px;
    margin-bottom: 75px;
  }
  .future-motion h1.future {
    min-width: 100%;
    max-width: 100%;
    text-align: center;
    margin-bottom: unset;
    font-size: 50px;
  }
  .future-motion h1.motion {
    text-align: center;
    min-width: 100%;
    max-width: 100%;
    font-size: 50px;
  }
  .img-1 {
    height: 300px;
    max-height: 300px;
    min-height: 300px;
  }
  .img-2 {
    height: 300px;
    max-height: 300px;
    min-height: 300px;
  }
  .img-3 {
    height: 300px;
    max-height: 300px;
    min-height: 300px;
  }
  .header-video-container {
    max-height: 550px;
    min-height: 550px;
  }
  .tri-bar-container {
    min-width: 100%;
    max-width: 100%;
  }
  .header-headline h1 {
    font-size: 30px;
    margin-top: 8px;
    margin-bottom: unset;
  }
}

@media screen and (max-width: 1500px) {
  .future-motion h1.future {
    min-width: 50%;
    max-width: 50%;
    /* text-align: center; */
    /* margin-bottom: unset; */
  }
  .future-motion h1.motion {
    /* text-align: center; */
    min-width: 50%;
    max-width: 50%;
  }
}

.main-projects-container-cs {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 200px;
}

.main-projects-container-cs h1 {
  font-size: 50px;
  font-family: 'Inter', sans-serif;
  font-weight: bolder;
  margin-top: 150px;
  margin-bottom: 155px;
  text-align: center;
}

.main-projects-container-cs h2 {
  border-bottom: 2px solid crimson;
  padding-left: 10px;
  padding-right: 10px;
}

.main-projects-container-cs p {
  font-size: large;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.gif {
  pointer-events: none;
  width: 40%;
  height: 0;
  padding-bottom: 20%;
  position: relative;
}

.gif-link {
  text-decoration: none;
  color: gray;
}

@media screen and (max-width: 1060px) {
  .gif {
    width: 100%;
    padding-bottom: 56%;
  }
}

.main-contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.contacts-header {
  font-size: 40px;
  font-family: 'Inter', sans-serif;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 700px;
  min-height: 700px;
}

.contacts-header video {
  width: 100%;
  height: 100%;
  max-height: 700px;
  min-height: 700px;
  object-fit: cover;
  z-index: 0;
  opacity: 0.30;
}

.contacts-header h1 {
  position: absolute;
  top: 0;
  width: 50%;
  margin-top: 250px;
  margin-bottom: 250px;
  text-align: center;
  border-bottom: 2px solid green;
  padding-left: 30px;
  padding-right: 30px;
}

.contact-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-top: 200px;
  padding-bottom: 200px;
}

.contact {
  max-width: 35%;
  min-width: 35%;
  padding: 20px;
}

.contact h1 {
  border-bottom: 2px solid crimson;
  font-size: 30px;
  font-family: 'Inter', sans-serif;
}

.contact h3 {
  font-size: 18px;
  font-family: 'Inter', sans-serif;
}

.contact p {
  font-size: 16px;
}

.contact a {
  text-decoration: none;
  color: rgb(143, 143, 143);
  font-size: 16px;
  margin-bottom: 8px;
}

.contact a:hover {
  text-decoration: overline;
  -webkit-text-decoration-color: green;
          text-decoration-color: green;
  color: white;
}

.header-video-container video {
  width: 100%;
  height: 100%;
  max-height: 700px;
  min-height: 700px;
  object-fit: cover;
  z-index: 0;
}

.contect-info-links {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.brecht-headshot {
  -webkit-transform: skewX(-3deg);
          transform: skewX(-3deg);
  width: 150px;
  height: 150px;
  position: relative;
  overflow: hidden;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  margin-left: 9px;
}

.brecht-headshot::before {
  content: "";
  -webkit-transform: skewX(3deg);
          transform: skewX(3deg);
  background-image: url(/static/media/brecht.77188594.jpeg);
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  position: absolute;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  left: -9px;
  width: 160px;
  height: 150px;
}

.mike-headshot {
  -webkit-transform: skewX(-3deg);
          transform: skewX(-3deg);
  width: 150px;
  height: 150px;
  position: relative;
  overflow: hidden;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  margin-left: 9px;
}

.mike-headshot::before {
  content: "";
  -webkit-transform: skewX(3deg);
          transform: skewX(3deg);
  background-image: url(/static/media/mike.be0c3c26.jpg);
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  position: absolute;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  left: -9px;
  width: 160px;
  height: 150px;
}

.skewed2 {
  position: absolute;
  top: 0;
  left: 0;
  border-left-width: 100vw;
  border-top-width: 0;
  border-right-width: 0;
  border-bottom-width: 33px;
  border-color: transparent transparent transparent #252525;
  border-style: solid;
  z-index: 100;
}

.skewed3 {
  position: absolute;
  bottom: 0;
  left: 0;
  border-left-width: 100vw;
  border-top-width: 33px;
  border-right-width: 0;
  border-bottom-width: 0;
  border-color: transparent transparent transparent #252525;
  border-style: solid;
  z-index: 100;
}

.skewed3-u {
  position: absolute;
  bottom: 0;
  left: 0;
  border-left-width: 0;
  border-top-width: 33px;
  border-right-width: 100vw;
  border-bottom-width: 0;
  border-color: transparent #252525 #252525 #252525;
  border-style: solid;
  z-index: 100;
}

@media screen and (max-width: 1060px) {
  .contact-bar {
    flex-direction: column;
  }
  .skewed2 {
    border-bottom-width: 50px;
  }
  .skewed3 {
    border-top-width: 50px;
  }
  .skewed3-u {
    border-top-width: 50px;
  }
  .contact {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
  }
  .contacts-header {
    font-size: 30px;
    max-height: 400px;
    min-height: 400px;
  }
  .contacts-header video {
    max-height: 400px;
    min-height: 400px;
  }
  .contacts-header h1 {
    width: 90%;
    margin-top: 100px;
    margin-bottom: 100px;
  }
}

.doc-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

.header-title {
  margin-bottom: 300px;
  margin-top: 300px;
  font-weight: bolder;
  font-family: 'Inter', sans-serif;
  font-size: xx-large;
  text-align: center;
  border-bottom: 2px solid green;
  margin-left: 100px;
  margin-right: 100px;
  word-wrap: break-word;
}

.header-btn-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.header-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  text-align: center;
  color: white;
  border: 1px solid white;
  max-width: 140px;
  min-width: 140px;
  cursor: pointer;
  font-weight: bolder;
  font-family: 'Inter', sans-serif;
  margin-bottom: 25px;
  min-height: 40px;
  max-width: 40px;
}

.header-btn:hover {
  color: black;
  background-color: white;
  border-color: crimson;
}

.pdf-nav-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: #252525;
  padding: 10px;
  text-align: center;
}

.doc-page {
  margin-top: 15px;
}

.page-input {
  outline: none;
  max-width: 20px;
  min-width: 20px;
}

.page-span {
  font-size: large;
  margin-right: 20px;
}

.pipe-span {
  color: crimson;
  font-size: 30px;
}

.download-span {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  font-size: large;
}

.download-span:hover {
  color: green;
}

.top-span {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: large;
}

.document-main-pre {
  background-color: rgb(139, 139, 139);
  padding: 10px;
  margin: 12px;
}

.page-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: calc((100vw / 3) * 2);
  max-width: calc((100vw / 3) * 2);
  height: 600px;
  background-color: black;
  flex-direction: column;
  font-family: 'Inter', sans-serif;
}

.page-loader {
  border: 5px solid black;
  border-top: 5px solid white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
  margin-left: 10px;
  margin-right: 10px;
}

.page-loader:hover {
  border: 10px solid white;
  border-top: 10px solid black;
}

.button-footer-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media screen and (max-width: 1060px) {
  .document-main-pre {
    overflow-x: hidden;
  }
  .page-loader-container {
    min-width: calc(100vw - 50px);
    max-width: calc(100vw - 50px);
  }
  .header-title {
    margin-bottom: 150px;
    margin-top: 100px;
    font-size: x-large;
  }
}

@media screen and (max-width: 300px) {
  .header-btn {
    max-width: 100px;
    min-width: 100px;
    min-height: 40px;
    max-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header-title {
    margin-bottom: 150px;
    margin-top: 50px;
    font-size: large;
  }
}

.main-update-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.updates-header {
  font-size: 40px;
  font-family: "Inter", sans-serif;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 700px;
  min-height: 700px;
}

.updates-header video {
  width: 100%;
  height: 100%;
  max-height: 700px;
  min-height: 700px;
  object-fit: cover;
  z-index: 0;
  opacity: 0.3;
}

.updates-header h1 {
  position: absolute;
  top: 0;
  width: 50%;
  margin-top: 250px;
  margin-bottom: 250px;
  text-align: center;
  border-bottom: 2px solid green;
  padding-left: 30px;
  padding-right: 30px;
}

.header-video-container video {
  width: 100%;
  height: 100%;
  max-height: 700px;
  min-height: 700px;
  object-fit: cover;
  z-index: 0;
}

.skewed2 {
  position: absolute;
  top: 0;
  left: 0;
  border-left-width: 100vw;
  border-top-width: 0;
  border-right-width: 0;
  border-bottom-width: 33px;
  border-color: transparent transparent transparent #252525;
  border-style: solid;
  z-index: 100;
}

.skewed3 {
  position: absolute;
  bottom: 0;
  left: 0;
  border-left-width: 100vw;
  border-top-width: 33px;
  border-right-width: 0;
  border-bottom-width: 0;
  border-color: transparent transparent transparent #252525;
  border-style: solid;
  z-index: 100;
}

.skewed3-u {
  position: absolute;
  bottom: 0;
  left: 0;
  border-left-width: 0;
  border-top-width: 33px;
  border-right-width: 100vw;
  border-bottom-width: 0;
  border-color: transparent #252525 #252525 #252525;
  border-style: solid;
  z-index: 100;
}

.home-btn {
  cursor: pointer;
  padding: 10px;
  background-color: transparent;
  border: 1px solid green;
  color: white;
  font-family: "Inter", sans-serif;
  font-size: large;
  font-weight: bold;
}

.home-btn:hover {
  border-color: crimson;
  background-color: white;
  color: black;
}

.home-btn span {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  text-align: center;
}

.logs-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 200px;
  margin-bottom: 200px;
}

table {
  border-collapse: collapse;
  width: 90%;
  margin-top: 30px;
}
td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
tr:nth-child(even) {
  background-color: #252525;
}

@media screen and (max-width: 1060px) {
  .skewed2 {
    border-bottom-width: 50px;
  }
  .skewed3 {
    border-top-width: 50px;
  }
  .skewed3-u {
    border-top-width: 50px;
  }
  .updates-header {
    font-size: 30px;
    max-height: 400px;
    min-height: 400px;
  }
  .updates-header video {
    max-height: 400px;
    min-height: 400px;
  }
  .updates-header h1 {
    width: 90%;
    margin-top: 100px;
    margin-bottom: 100px;
  }
}

.nav-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  border-bottom: 2px solid #252525;
  padding-bottom: 25px;
}

.nav-bar-img {
  min-height: 170px;
  max-height: 170px;
  min-width: 170px;
  max-width: 170px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.footer-bar-img {
  min-height: 100px;
  max-height: 100px;
  min-width: 100px;
  max-width: 100px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.nav-club-name {
  font-family: "Racing Sans One", monospace;
  padding-left: 10px;
  font-size: 40px;
}

.nav-bar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-family: "Inter", sans-serif;
  margin-right: 20px;
}

.nav-spacer {
  display: block;
}

.alert-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-menu-item {
  cursor: pointer;
  max-width: 140px;
  min-width: 140px;
  min-height: 50px;
  max-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid transparent;
  text-decoration: none;
  margin-left: 3px;
}

.mscc {
  max-width: 90px;
  min-width: 90px;
}

.nav-menu-item.active {
  color: white;
  border-left: 1px solid white;
  border-right: 1px solid white;
}

.nav-menu-item:hover {
  z-index: 999;
  border-left: 1px solid crimson;
  border-right: 1px solid crimson;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  padding-top: 45px;
  padding-bottom: 55px;
  font-family: "Julius Sans One", sans-serif;
  font-weight: bolder;
  font-size: small;
  text-align: left;
  flex-direction: row;
  background-color: #252525;
}

.footer-bar-main-container {
  min-height: 100px;
}

.footer-contact-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.footer-contact-bar div {
  min-width: 160px;
  max-width: 180px;
  margin-bottom: 35px;
}

.footer-contact-bar div h2 {
  margin: unset;
  color: white;
  min-width: 100%;
  max-width: 100%;
  padding-bottom: 5px;
  border-bottom: 2px solid green;
  margin-bottom: 10px;
}

.footer-contact-bar div.mike {
  margin-left: 50px;
}

.footer-contact-bar div.brecht {
  margin-left: 10px;
}

.footer-phone {
  color: white;
  text-decoration: none;
  font-size: larger;
}

.footer-phone:hover {
  text-decoration: overline;
  -webkit-text-decoration-color: crimson;
          text-decoration-color: crimson;
  color: #999999;
}

.footer-email {
  padding-left: 10px;
  margin-top: 15px;
  color: white;
  text-decoration: none;
  font-size: larger;
}

.footer-email:hover {
  text-decoration: overline;
  -webkit-text-decoration-color: crimson;
          text-decoration-color: crimson;
  color: #999999;
}

.footer-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.footer-nav-container {
  margin-right: 25px;
  min-height: 102px;
  display: flex;
  flex-direction: column;
}

.footer-nav a {
  color: white;
  text-decoration: none;
  min-width: 100px;
  max-width: 100px;
  height: 40px;
  font-size: medium;
  text-align: center;
}

.footer-nav a:hover {
  text-decoration: overline;
  -webkit-text-decoration-color: green;
          text-decoration-color: green;
  text-decoration-thickness: 2px;
}

.footer-nav a.locked {
  color: gray;
}

#footer-cpyrt {
  color: crimson;
  font-size: small;
  text-align: right;
  margin-bottom: unset;
  word-wrap: break-word;
  max-width: 100%;
}

#footer-cpyrt span {
  color: white;
}

.footer-site-made-by {
  color: rgb(80, 80, 80);
  background-color: #252525;
  margin-top: -20px;
  margin-bottom: unset;
  text-align: end;
  padding-right: 35px;
  padding-bottom: 3px;
  font-size: x-small;
}

.footer-site-made-by a {
  color: rgb(80, 80, 80);
  text-decoration: underline;
}

.footer-site-made-by a:hover {
  color: gray;
}

.cntr {
  text-align: center;
}

.dropdown {
  flex-grow: 1;
  display: flex;
  min-height: 100%;
  max-height: 100%;
  min-width: 100%;
  max-width: 100%;
}

.dropdown a.main {
  font-size: large;
  margin: unset;
  font-family: "Inter", sans-serif;
  color: white;
  font-weight: bolder;
  text-decoration: none;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.dropdown a.main.locked {
  color: gray;
}

.dropdown a.main:hover {
  text-decoration: overline;
  -webkit-text-decoration-color: green;
          text-decoration-color: green;
  text-decoration-thickness: 2px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #474747;
  margin-top: 50px;
  min-width: 130px;
  max-width: 130px;
  box-shadow: 0px 8px 16px 0px rgba(255, 255, 255, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: white;
  font-weight: bolder;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a.disabled {
  pointer-events: none !important;
}

.dropdown-content a:hover {
  background-color: rgb(82, 82, 82);
}

.nav-menu-item:hover .dropdown-content {
  display: block;
}

.last-update-link-container {
  min-height: 170px;
  max-height: 170px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: medium;
  color: white;
  text-decoration: none;
}

.last-update-link-container:hover {
  text-decoration: overline;
  -webkit-text-decoration-color: green;
          text-decoration-color: green;
  text-decoration-thickness: 2px;
}

@media screen and (max-width: 1060px) {
  .footer {
    flex-direction: column;
  }
  .nav-bar {
    flex-direction: column;
  }
  .nav-bar h2 {
    margin: unset;
  }
  .nav-bar-img {
    padding-top: 20px;
    margin-bottom: 15px;
  }
  .nav-bar-container {
    flex-direction: column;
    margin-right: unset;
  }
  .nav-spacer {
    display: none;
  }
  .nav-menu-item {
    max-width: 160px;
    min-width: 160px;
    margin-bottom: 10px;
  }
  .mscc {
    max-width: 160px;
    min-width: 160px;
  }
  .nav-club-name {
    text-align: center;
  }
  .dropdown-content {
    min-width: 160px;
    max-width: 160px;
  }
  .dropdown-content a:active {
    background-color: rgb(82, 82, 82);
  }
  .dropdown a.main:active {
    text-decoration: overline;
    -webkit-text-decoration-color: green;
            text-decoration-color: green;
    text-decoration-thickness: 2px;
  }
  .nav-menu-item:active .dropdown-content {
    display: block;
  }
  .footer-contact-bar {
    flex-direction: column;
  }
  .footer-contact-bar div.mike {
    margin-left: unset;
  }
  .footer-contact-bar div.brecht {
    margin-left: unset;
    margin-top: 15px;
    margin-bottom: 30px;
  }
  .footer-email {
    padding-left: unset;
  }
  .footer-nav-container {
    margin-right: unset;
  }
  .footer-nav {
    margin-top: 30px;
    flex-direction: column;
  }
  .footer-nav a {
    margin-top: 10px;
  }
  #footer-cpyrt {
    text-align: center;
  }
  .footer-site-made-by {
    text-align: center;
    padding-right: unset;
  }
  .last-update-link-container {
    min-height: 50px;
    max-height: 50px;
    margin-bottom: 35px;
  }
}

