.main-projects-container-cs {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 200px;
}

.main-projects-container-cs h1 {
  font-size: 50px;
  font-family: 'Inter', sans-serif;
  font-weight: bolder;
  margin-top: 150px;
  margin-bottom: 155px;
  text-align: center;
}

.main-projects-container-cs h2 {
  border-bottom: 2px solid crimson;
  padding-left: 10px;
  padding-right: 10px;
}

.main-projects-container-cs p {
  font-size: large;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.gif {
  pointer-events: none;
  width: 40%;
  height: 0;
  padding-bottom: 20%;
  position: relative;
}

.gif-link {
  text-decoration: none;
  color: gray;
}

@media screen and (max-width: 1060px) {
  .gif {
    width: 100%;
    padding-bottom: 56%;
  }
}
