.doc-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

.header-title {
  margin-bottom: 300px;
  margin-top: 300px;
  font-weight: bolder;
  font-family: 'Inter', sans-serif;
  font-size: xx-large;
  text-align: center;
  border-bottom: 2px solid green;
  margin-left: 100px;
  margin-right: 100px;
  word-wrap: break-word;
}

.header-btn-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.header-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  text-align: center;
  color: white;
  border: 1px solid white;
  max-width: 140px;
  min-width: 140px;
  cursor: pointer;
  font-weight: bolder;
  font-family: 'Inter', sans-serif;
  margin-bottom: 25px;
  min-height: 40px;
  max-width: 40px;
}

.header-btn:hover {
  color: black;
  background-color: white;
  border-color: crimson;
}

.pdf-nav-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: #252525;
  padding: 10px;
  text-align: center;
}

.doc-page {
  margin-top: 15px;
}

.page-input {
  outline: none;
  max-width: 20px;
  min-width: 20px;
}

.page-span {
  font-size: large;
  margin-right: 20px;
}

.pipe-span {
  color: crimson;
  font-size: 30px;
}

.download-span {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  font-size: large;
}

.download-span:hover {
  color: green;
}

.top-span {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: large;
}

.document-main-pre {
  background-color: rgb(139, 139, 139);
  padding: 10px;
  margin: 12px;
}

.page-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: calc((100vw / 3) * 2);
  max-width: calc((100vw / 3) * 2);
  height: 600px;
  background-color: black;
  flex-direction: column;
  font-family: 'Inter', sans-serif;
}

.page-loader {
  border: 5px solid black;
  border-top: 5px solid white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-left: 10px;
  margin-right: 10px;
}

.page-loader:hover {
  border: 10px solid white;
  border-top: 10px solid black;
}

.button-footer-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1060px) {
  .document-main-pre {
    overflow-x: hidden;
  }
  .page-loader-container {
    min-width: calc(100vw - 50px);
    max-width: calc(100vw - 50px);
  }
  .header-title {
    margin-bottom: 150px;
    margin-top: 100px;
    font-size: x-large;
  }
}

@media screen and (max-width: 300px) {
  .header-btn {
    max-width: 100px;
    min-width: 100px;
    min-height: 40px;
    max-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header-title {
    margin-bottom: 150px;
    margin-top: 50px;
    font-size: large;
  }
}
