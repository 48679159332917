.main-contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.contacts-header {
  font-size: 40px;
  font-family: 'Inter', sans-serif;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 700px;
  min-height: 700px;
}

.contacts-header video {
  width: 100%;
  height: 100%;
  max-height: 700px;
  min-height: 700px;
  object-fit: cover;
  z-index: 0;
  opacity: 0.30;
}

.contacts-header h1 {
  position: absolute;
  top: 0;
  width: 50%;
  margin-top: 250px;
  margin-bottom: 250px;
  text-align: center;
  border-bottom: 2px solid green;
  padding-left: 30px;
  padding-right: 30px;
}

.contact-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-top: 200px;
  padding-bottom: 200px;
}

.contact {
  max-width: 35%;
  min-width: 35%;
  padding: 20px;
}

.contact h1 {
  border-bottom: 2px solid crimson;
  font-size: 30px;
  font-family: 'Inter', sans-serif;
}

.contact h3 {
  font-size: 18px;
  font-family: 'Inter', sans-serif;
}

.contact p {
  font-size: 16px;
}

.contact a {
  text-decoration: none;
  color: rgb(143, 143, 143);
  font-size: 16px;
  margin-bottom: 8px;
}

.contact a:hover {
  text-decoration: overline;
  text-decoration-color: green;
  color: white;
}

.header-video-container video {
  width: 100%;
  height: 100%;
  max-height: 700px;
  min-height: 700px;
  object-fit: cover;
  z-index: 0;
}

.contect-info-links {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.brecht-headshot {
  transform: skewX(-3deg);
  width: 150px;
  height: 150px;
  position: relative;
  overflow: hidden;
  transform-origin: top left;
  margin-left: 9px;
}

.brecht-headshot::before {
  content: "";
  transform: skewX(3deg);
  background-image: url("../../../images/headshots/brecht.jpeg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  position: absolute;
  transform-origin: top left;
  left: -9px;
  width: 160px;
  height: 150px;
}

.mike-headshot {
  transform: skewX(-3deg);
  width: 150px;
  height: 150px;
  position: relative;
  overflow: hidden;
  transform-origin: top left;
  margin-left: 9px;
}

.mike-headshot::before {
  content: "";
  transform: skewX(3deg);
  background-image: url("../../../images/headshots/mike.jpg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  position: absolute;
  transform-origin: top left;
  left: -9px;
  width: 160px;
  height: 150px;
}

.skewed2 {
  position: absolute;
  top: 0;
  left: 0;
  border-left-width: 100vw;
  border-top-width: 0;
  border-right-width: 0;
  border-bottom-width: 33px;
  border-color: transparent transparent transparent #252525;
  border-style: solid;
  z-index: 100;
}

.skewed3 {
  position: absolute;
  bottom: 0;
  left: 0;
  border-left-width: 100vw;
  border-top-width: 33px;
  border-right-width: 0;
  border-bottom-width: 0;
  border-color: transparent transparent transparent #252525;
  border-style: solid;
  z-index: 100;
}

.skewed3-u {
  position: absolute;
  bottom: 0;
  left: 0;
  border-left-width: 0;
  border-top-width: 33px;
  border-right-width: 100vw;
  border-bottom-width: 0;
  border-color: transparent #252525 #252525 #252525;
  border-style: solid;
  z-index: 100;
}

@media screen and (max-width: 1060px) {
  .contact-bar {
    flex-direction: column;
  }
  .skewed2 {
    border-bottom-width: 50px;
  }
  .skewed3 {
    border-top-width: 50px;
  }
  .skewed3-u {
    border-top-width: 50px;
  }
  .contact {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
  }
  .contacts-header {
    font-size: 30px;
    max-height: 400px;
    min-height: 400px;
  }
  .contacts-header video {
    max-height: 400px;
    min-height: 400px;
  }
  .contacts-header h1 {
    width: 90%;
    margin-top: 100px;
    margin-bottom: 100px;
  }
}
