.logo-1 {
  background-image: url("../../../images/shields/shield_01.png");
}

.logo-2 {
  background-image: url("../../../images/shields/sheild_02.png");
}

.logo-3 {
  background-image: url("../../../images/shields/sheild_03.png");
}

.logo-4 {
  background-image: url("../../../images/shields/sheild_04.png");
}

.logo-5 {
  background-image: url("../../../images/shields/sheild_05.png");
}

.logo-6 {
  background-image: url("../../../images/shields/sheild_06.png");
}

.logo-7 {
  background-image: url("../../../images/shields/sheild_07.png");
}

.logo-8 {
  background-image: url("../../../images/shields/sheild_08.png");
}

.logo-9 {
  background-image: url("../../../images/shields/sheild_09.png");
}

.logo-10 {
  background-image: url("../../../images/shields/sheild_10.png");
}

.logo-11 {
  background-image: url("../../../images/shields/sheild_11.png");
}

.logo-12 {
  background-image: url("../../../images/shields/sheild_12.png");
}

.logo-main {
  background-image: url("../../../images/logo-main.png");
}
