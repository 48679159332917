section {
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  overflow: hidden;
}

section .sec1 {
  /* background: url("../images/home.jpg"); */
  background-size: cover;
  background-position: center center;
  height: 500px;
}

section .sec2 {
  background: #fff;
  height: 450px;
}

section .sec3 {
  background: lightgray;
  background-size: cover;
  background-position: center center;
  height: 800px;
}

section .sec4 {
  background: #fff;
  height: 800px;
}

.skewed1 {
  position: absolute;
  bottom: 0;
  left: 0;
  border-left-width: 100vw;
  border-top-width: 33px;
  border-right-width: 0;
  border-bottom-width: 0;
  border-color: transparent transparent transparent #252525;
  border-style: solid;
  z-index: 100;
}

.skewed2 {
  position: absolute;
  top: 0;
  left: 0;
  border-left-width: 100vw;
  border-top-width: 0;
  border-right-width: 0;
  border-bottom-width: 33px;
  border-color: transparent transparent transparent #252525;
  border-style: solid;
  z-index: 100;
}

.skewed2-u {
  position: absolute;
  top: 0;
  left: 0;
  border-left-width: 100vw;
  border-top-width: 33px;
  border-right-width: 0;
  border-bottom-width: 0;
  border-color: #252525 transparent transparent transparent;
  border-style: solid;
  z-index: 100;
}

.skewed3 {
  position: absolute;
  bottom: 0;
  left: 0;
  border-left-width: 100vw;
  border-top-width: 33px;
  border-right-width: 0;
  border-bottom-width: 0;
  border-color: transparent transparent transparent #252525;
  border-style: solid;
  z-index: 100;
}

.skewed3-u {
  position: absolute;
  bottom: 0;
  left: 0;
  border-left-width: 0;
  border-top-width: 33px;
  border-right-width: 100vw;
  border-bottom-width: 0;
  border-color: transparent #252525 #252525 #252525;
  border-style: solid;
  z-index: 100;
}

.final-sec {
  height: 200px;
  padding: 20px;
  max-width: 100%;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pdf-view {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.pdf-button {
  cursor: pointer;
  padding: 10px;
  background-color: transparent;
  border: 1px solid green;
  color: white;
  font-family: "Inter", sans-serif;
  font-size: large;
  font-weight: bold;
}

.pdf-button:hover {
  border-color: crimson;
  background-color: white;
  color: black;
}

.pdf-button span {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  text-align: center;
}

.inverse {
  background-color: black;
}

.inverse:hover {
  background-color: transparent;
  color: black;
}

.header-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.header-video-container {
  max-height: 750px;
  min-height: 750px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-headlies-container {
  min-width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.header-headline-row {
  min-width: calc(100% - 40px);
  max-width: calc(100% - 40px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-left: 20px;
  padding-right: 20px;
}

.header-headline {
  flex: 1;
  padding: 15px;
  text-align: center;
  margin-top: 200px;
  margin-bottom: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.header-headline h1 {
  font-size: 40px;
  margin-top: 8px;
  margin-bottom: unset;
}

.header-headline p.text {
  text-align: center;
  font-size: 20px;
}

.header-content p {
  padding: 25px;
  text-align: left;
}

.header-content p a {
  text-decoration: none;
  color: green;
}

.header-content p a:hover {
  text-decoration: overline;
  color: rgb(0, 200, 0);
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;
}

.col {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
  margin-left: 15px;
  flex-direction: column;
  flex: 1;
  margin-bottom: 50px;
}

.col2 {
  flex: 1;
  background-color: #252525;
}

.col p {
  font-size: large;
}

.img-1 {
  background: url("../../../images/architecture.jpg");
  background-size: cover;
  background-position: center center;
  height: 800px;
  max-height: 800px;
  min-height: 800px;
  width: 100%;
  margin: unset;
}

.img-2 {
  background: url("../../../images/porsche1.jpg");
  background-size: cover;
  background-position: center center;
  height: 800px;
  max-height: 800px;
  min-height: 800px;
  width: 100%;
  margin: unset;
}

.img-3 {
  background: url("../../../images/backyard.jpeg");
  background-size: cover;
  background-position: center center;
  height: 800px;
  max-height: 800px;
  min-height: 300px;
  width: 100%;
  margin: unset;
}

.col h1 {
  border-bottom: 1px solid crimson;
  font-size: 30px;
}

.col2 video {
  width: 100%;
  min-height: 800px;
  object-fit: cover;
  z-index: -1;
  opacity: 0.85;
}

.future-motion {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 200px;
  margin-bottom: 200px;
  transform: skew(-4deg, -4deg);
}

.future-motion h1.future {
  font-size: 120px;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-weight: bolder;
  color: black;
  margin: unset;
}

.tri-bar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 60%;
  max-width: 60%;
  margin-bottom: 50px;
}

.tri-bar-container .red {
  flex: 1;
  background-color: crimson;
  height: 2px;
}

.tri-bar-container .green {
  flex: 1;
  background-color: green;
  height: 2px;
}

.tri-bar-container .white {
  flex: 1;
  background-color: white;
  height: 2px;
}

.project-overview {
  background-color: #fff;
  color: #252525;
}

@media screen and (max-width: 1070px) {
  .row {
    flex-direction: column;
  }
  .col {
    margin-left: unset;
  }
  .future-motion {
    margin-top: 100px;
    margin-bottom: 75px;
  }
  .future-motion h1.future {
    min-width: 100%;
    max-width: 100%;
    text-align: center;
    margin-bottom: unset;
    font-size: 50px;
  }
  .future-motion h1.motion {
    text-align: center;
    min-width: 100%;
    max-width: 100%;
    font-size: 50px;
  }
  .img-1 {
    height: 300px;
    max-height: 300px;
    min-height: 300px;
  }
  .img-2 {
    height: 300px;
    max-height: 300px;
    min-height: 300px;
  }
  .img-3 {
    height: 300px;
    max-height: 300px;
    min-height: 300px;
  }
  .header-video-container {
    max-height: 550px;
    min-height: 550px;
  }
  .tri-bar-container {
    min-width: 100%;
    max-width: 100%;
  }
  .header-headline h1 {
    font-size: 30px;
    margin-top: 8px;
    margin-bottom: unset;
  }
}

@media screen and (max-width: 1500px) {
  .future-motion h1.future {
    min-width: 50%;
    max-width: 50%;
    /* text-align: center; */
    /* margin-bottom: unset; */
  }
  .future-motion h1.motion {
    /* text-align: center; */
    min-width: 50%;
    max-width: 50%;
  }
}
