.nav-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  border-bottom: 2px solid #252525;
  padding-bottom: 25px;
}

.nav-bar-img {
  min-height: 170px;
  max-height: 170px;
  min-width: 170px;
  max-width: 170px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.footer-bar-img {
  min-height: 100px;
  max-height: 100px;
  min-width: 100px;
  max-width: 100px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.nav-club-name {
  font-family: "Racing Sans One", monospace;
  padding-left: 10px;
  font-size: 40px;
}

.nav-bar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-family: "Inter", sans-serif;
  margin-right: 20px;
}

.nav-spacer {
  display: block;
}

.alert-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-menu-item {
  cursor: pointer;
  max-width: 140px;
  min-width: 140px;
  min-height: 50px;
  max-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid transparent;
  text-decoration: none;
  margin-left: 3px;
}

.mscc {
  max-width: 90px;
  min-width: 90px;
}

.nav-menu-item.active {
  color: white;
  border-left: 1px solid white;
  border-right: 1px solid white;
}

.nav-menu-item:hover {
  z-index: 999;
  border-left: 1px solid crimson;
  border-right: 1px solid crimson;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  padding-top: 45px;
  padding-bottom: 55px;
  font-family: "Julius Sans One", sans-serif;
  font-weight: bolder;
  font-size: small;
  text-align: left;
  flex-direction: row;
  background-color: #252525;
}

.footer-bar-main-container {
  min-height: 100px;
}

.footer-contact-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.footer-contact-bar div {
  min-width: 160px;
  max-width: 180px;
  margin-bottom: 35px;
}

.footer-contact-bar div h2 {
  margin: unset;
  color: white;
  min-width: 100%;
  max-width: 100%;
  padding-bottom: 5px;
  border-bottom: 2px solid green;
  margin-bottom: 10px;
}

.footer-contact-bar div.mike {
  margin-left: 50px;
}

.footer-contact-bar div.brecht {
  margin-left: 10px;
}

.footer-phone {
  color: white;
  text-decoration: none;
  font-size: larger;
}

.footer-phone:hover {
  text-decoration: overline;
  text-decoration-color: crimson;
  color: #999999;
}

.footer-email {
  padding-left: 10px;
  margin-top: 15px;
  color: white;
  text-decoration: none;
  font-size: larger;
}

.footer-email:hover {
  text-decoration: overline;
  text-decoration-color: crimson;
  color: #999999;
}

.footer-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.footer-nav-container {
  margin-right: 25px;
  min-height: 102px;
  display: flex;
  flex-direction: column;
}

.footer-nav a {
  color: white;
  text-decoration: none;
  min-width: 100px;
  max-width: 100px;
  height: 40px;
  font-size: medium;
  text-align: center;
}

.footer-nav a:hover {
  text-decoration: overline;
  text-decoration-color: green;
  text-decoration-thickness: 2px;
}

.footer-nav a.locked {
  color: gray;
}

#footer-cpyrt {
  color: crimson;
  font-size: small;
  text-align: right;
  margin-bottom: unset;
  word-wrap: break-word;
  max-width: 100%;
}

#footer-cpyrt span {
  color: white;
}

.footer-site-made-by {
  color: rgb(80, 80, 80);
  background-color: #252525;
  margin-top: -20px;
  margin-bottom: unset;
  text-align: end;
  padding-right: 35px;
  padding-bottom: 3px;
  font-size: x-small;
}

.footer-site-made-by a {
  color: rgb(80, 80, 80);
  text-decoration: underline;
}

.footer-site-made-by a:hover {
  color: gray;
}

.cntr {
  text-align: center;
}

.dropdown {
  flex-grow: 1;
  display: flex;
  min-height: 100%;
  max-height: 100%;
  min-width: 100%;
  max-width: 100%;
}

.dropdown a.main {
  font-size: large;
  margin: unset;
  font-family: "Inter", sans-serif;
  color: white;
  font-weight: bolder;
  text-decoration: none;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.dropdown a.main.locked {
  color: gray;
}

.dropdown a.main:hover {
  text-decoration: overline;
  text-decoration-color: green;
  text-decoration-thickness: 2px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #474747;
  margin-top: 50px;
  min-width: 130px;
  max-width: 130px;
  box-shadow: 0px 8px 16px 0px rgba(255, 255, 255, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: white;
  font-weight: bolder;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a.disabled {
  pointer-events: none !important;
}

.dropdown-content a:hover {
  background-color: rgb(82, 82, 82);
}

.nav-menu-item:hover .dropdown-content {
  display: block;
}

.last-update-link-container {
  min-height: 170px;
  max-height: 170px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: medium;
  color: white;
  text-decoration: none;
}

.last-update-link-container:hover {
  text-decoration: overline;
  text-decoration-color: green;
  text-decoration-thickness: 2px;
}

@media screen and (max-width: 1060px) {
  .footer {
    flex-direction: column;
  }
  .nav-bar {
    flex-direction: column;
  }
  .nav-bar h2 {
    margin: unset;
  }
  .nav-bar-img {
    padding-top: 20px;
    margin-bottom: 15px;
  }
  .nav-bar-container {
    flex-direction: column;
    margin-right: unset;
  }
  .nav-spacer {
    display: none;
  }
  .nav-menu-item {
    max-width: 160px;
    min-width: 160px;
    margin-bottom: 10px;
  }
  .mscc {
    max-width: 160px;
    min-width: 160px;
  }
  .nav-club-name {
    text-align: center;
  }
  .dropdown-content {
    min-width: 160px;
    max-width: 160px;
  }
  .dropdown-content a:active {
    background-color: rgb(82, 82, 82);
  }
  .dropdown a.main:active {
    text-decoration: overline;
    text-decoration-color: green;
    text-decoration-thickness: 2px;
  }
  .nav-menu-item:active .dropdown-content {
    display: block;
  }
  .footer-contact-bar {
    flex-direction: column;
  }
  .footer-contact-bar div.mike {
    margin-left: unset;
  }
  .footer-contact-bar div.brecht {
    margin-left: unset;
    margin-top: 15px;
    margin-bottom: 30px;
  }
  .footer-email {
    padding-left: unset;
  }
  .footer-nav-container {
    margin-right: unset;
  }
  .footer-nav {
    margin-top: 30px;
    flex-direction: column;
  }
  .footer-nav a {
    margin-top: 10px;
  }
  #footer-cpyrt {
    text-align: center;
  }
  .footer-site-made-by {
    text-align: center;
    padding-right: unset;
  }
  .last-update-link-container {
    min-height: 50px;
    max-height: 50px;
    margin-bottom: 35px;
  }
}
