.main-update-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.updates-header {
  font-size: 40px;
  font-family: "Inter", sans-serif;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 700px;
  min-height: 700px;
}

.updates-header video {
  width: 100%;
  height: 100%;
  max-height: 700px;
  min-height: 700px;
  object-fit: cover;
  z-index: 0;
  opacity: 0.3;
}

.updates-header h1 {
  position: absolute;
  top: 0;
  width: 50%;
  margin-top: 250px;
  margin-bottom: 250px;
  text-align: center;
  border-bottom: 2px solid green;
  padding-left: 30px;
  padding-right: 30px;
}

.header-video-container video {
  width: 100%;
  height: 100%;
  max-height: 700px;
  min-height: 700px;
  object-fit: cover;
  z-index: 0;
}

.skewed2 {
  position: absolute;
  top: 0;
  left: 0;
  border-left-width: 100vw;
  border-top-width: 0;
  border-right-width: 0;
  border-bottom-width: 33px;
  border-color: transparent transparent transparent #252525;
  border-style: solid;
  z-index: 100;
}

.skewed3 {
  position: absolute;
  bottom: 0;
  left: 0;
  border-left-width: 100vw;
  border-top-width: 33px;
  border-right-width: 0;
  border-bottom-width: 0;
  border-color: transparent transparent transparent #252525;
  border-style: solid;
  z-index: 100;
}

.skewed3-u {
  position: absolute;
  bottom: 0;
  left: 0;
  border-left-width: 0;
  border-top-width: 33px;
  border-right-width: 100vw;
  border-bottom-width: 0;
  border-color: transparent #252525 #252525 #252525;
  border-style: solid;
  z-index: 100;
}

.home-btn {
  cursor: pointer;
  padding: 10px;
  background-color: transparent;
  border: 1px solid green;
  color: white;
  font-family: "Inter", sans-serif;
  font-size: large;
  font-weight: bold;
}

.home-btn:hover {
  border-color: crimson;
  background-color: white;
  color: black;
}

.home-btn span {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  text-align: center;
}

.logs-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 200px;
  margin-bottom: 200px;
}

table {
  border-collapse: collapse;
  width: 90%;
  margin-top: 30px;
}
td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
tr:nth-child(even) {
  background-color: #252525;
}

@media screen and (max-width: 1060px) {
  .skewed2 {
    border-bottom-width: 50px;
  }
  .skewed3 {
    border-top-width: 50px;
  }
  .skewed3-u {
    border-top-width: 50px;
  }
  .updates-header {
    font-size: 30px;
    max-height: 400px;
    min-height: 400px;
  }
  .updates-header video {
    max-height: 400px;
    min-height: 400px;
  }
  .updates-header h1 {
    width: 90%;
    margin-top: 100px;
    margin-bottom: 100px;
  }
}
