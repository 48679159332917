.pr-loading-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Inter', sans-serif;
  font-weight: bolder;
}

.pr-loading-logo {
  width: 100px;
  height: 100px;
  margin-bottom: 15px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
