@import url('https://fonts.googleapis.com/css2?family=Inter:wght@900&display=swap');

@keyframes fadeUp {
  from {
    transform: translate3d(0, 55px, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.logo-header {
  width: 100%;
  height: 185px;
  margin-top: 30px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
}

.main-bkgd {
  min-height: 100vh;
  max-height: 100vh;
  min-width: 100vw;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-bkgd video {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: 0;
  filter: grayscale(1);
}

.home-content {
  position: absolute;
  top: 0;
  min-height: 100%;
  max-height: 100%;
  min-width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
}

.code-input-container {
  display: none;
}

.code-input-container.open {
  display: block;
  animation-name: fadeUp;
  animation-duration: 1.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

h1.invitation {
  font-family: 'Inter', sans-serif;
  padding-left: 20px;
  padding-right: 20px;
}

.invite-code-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.invite-code-form input {
  height: 45px;
  width: fit-content;
  background-color: rgba(0, 0, 0, 0.322);
  border: 2px solid white;
  color: white;
  font-family: 'Inter', sans-serif;
  text-transform: capitalize;
  outline: none;
  font-weight: bold;
  font-size: larger;
  padding-left: 8px;
}

.invite-code-form button {
  height: 51px;
  background-color: white;
  border-top: 2px solid white;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  border-left: none;
  outline: none;
  color: black;
  font-size: larger;
  font-family: 'Inter', sans-serif;
  font-weight: bolder;
}

.invite-code-form button:hover {
  background-color: rgba(0, 0, 0, 0.322);
  color: white;
  cursor: pointer;
}

.invite-code-error {
  /* display: none; */
  height: 20px;
  margin-top: 18px;
}

.invite-code-error.open {
  display: block;
  color: crimson;
  font-family: 'Inter', sans-serif;
  font-size: large;
  padding-left: 20px;
  padding-right: 20px;
}

.spacer {
  flex-grow: 1;
}

.loader {
  border: 5px solid black;
  border-top: 5px solid white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin-left: 10px;
  margin-right: 10px;
}

.loader:hover {
  border: 5px solid white;
  border-top: 5px solid black;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 600px) {
  .intro-text {
    font-size: 30px;
  }
  .code-input-container {
    max-width: 95%;
  }
  .invite-code-form {
    max-width: 95%;
  }
  .invite-code-form input {
    width: 100%;
  }
}
